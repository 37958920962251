@charset "utf-8";

@import
	"./abstracts/variables",
	"./abstracts/functions",
	"./abstracts/mixins";

/**
* --------------------------------
* Home CSS
* --------------------------------
*/
#wrap #contents.home {
	padding-top: 0;
}

.home {
	.visual-area {
		position: relative;

		.visual-swiper {
			position: relative;
			width: 100%;
			overflow: hidden;
		}

		.swiper-slide {
			position: relative;
			width: 100%;
			height: 100vh;
			overflow: hidden;

			.pic {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}

			.mv {
				width: 100%;
				height: 100%;

				video {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}

			.txt {
				@include position-center;

				width: 100%;
				line-height: 22px;
				color: $color7;
				font-size: 16px;
				font-weight: 300;
				text-align: center;

				strong {
					display: block;
					margin-bottom: 15px;
					line-height: 45px;
					font-size: 40px;
					font-weight: 700;
					font-family: $font-family2;
					opacity: 0;
					transform: scale(.5);
					transition: all 0s ease-in-out 0s;

					&.kma60th {
						@include background(0, 0, $image-url-home + 'visual_kma60th.png', $background-size: 100%);

						display: block;
						margin: 0 auto 15px;
						width: 190px;
						height: 135px;
						text-align: left;
						text-indent: -9999em;
					}
				}

				p {
					opacity: 0;
					transition: opacity 0s ease-in-out 0s;

					.bar {
						display: inline-block;
						margin: 10px 5px 0;
						height: 1px;
						width: 26px;
						background-color: $color7;
						vertical-align: top;
					}
				}
			}
	
			.cover {
				@include position(0, 0);
				
				width: 100%;
				height: 100%;
				overflow: hidden;

				> div {
					@include position(0, 0);

					width: 100%;
					height: 100%;
				}

				.dot {
					@include background(0, 0, $image-url-home + 'visual_cover_dot.png', repeat, $background-size: 3px);
					opacity: .25;
				}

				.circle {
					@include background(50%, 50%, $image-url-home + 'visual_cover_white.png');

					top: 50%;
					left: 50%;
					width: 1620px;
					height: 1620px;
					transform: translate(-50%, -50%) scale(.5);
					transition: all 0s;
					background-size: 100% 100%;

					&::before {
						@include position(0, -100%);

						content: '';
						width: 100%;
						height: 100%;
						background-color: rgba(255, 255, 255, .5);
					}

					&::after {
						@include position(0, 100%);

						content: '';
						width: 100%;
						height: 100%;
						background-color: rgba(255, 255, 255, .5);
					}
				}

				.line {
					transform: scale(.5);
					transition: all 0s;

					&::before {
						@include position-center;

						content: '';
						width: 900px;
						height: 900px;
						border-radius: 100%;
						border: 1px solid rgba(255, 255, 255, .1);
					}

					&::after {
						@include position-center;

						content: '';
						width: 550px;
						height: 550px;
						border-radius: 100%;
						border: 1px solid rgba(255, 255, 255, .2);
					}
				}

				.dim {
					background-color: rgba(0, 0, 0, .3);
					transition: all 0s;
				}

				&.gray {
					.circle {
						background-image: url($image-url-home + 'visual_cover_gray.png');

						&::before {
							background-color: rgba(0, 0, 0, .5);
						}

						&::after {
							background-color: rgba(0, 0, 0, .5);
						}
					}
				}

				&.vertical {
					.circle {
						&::before {
							top: -100%;
							left: 0;
						}

						&::after {
							top: 100%;
							left: 0;
						}
					}
				}
			}

			&.active {
				.txt {
					strong {
						opacity: 1;
						transform: scale(1);
						transition: all .6s ease-in-out .4s;
					}

					p {
						opacity: 1;
						transition: opacity .6s ease-in-out 1s;
					}
				}

				.cover {
					.circle {
						transform: translate(-50%, -50%) scale(1);
						opacity: .4;
						transition: transform 1s;
					}

					.line {
						transform: scale(.4);
						animation: home-visual1 11s ease-in-out infinite;
					}

					.dim {
						opacity: 0;
						transition: opacity 1s;
					}
				}
			}
		}

		.swiper-pagination {
			bottom: 40px;
			padding-left: 122px;
			line-height: 50px;
			color: $color7;
			font-size: 13px;
			text-align: left;

			@include before-line(23px, 2px, $color: $color7);

			&::before {
				@include position(25px, 78px);
				opacity: .5;
			}

			.swiper-pagination-current,
			.swiper-pagination-total {
				display: inline-block;
				width: 20px;
				text-align: center;
				vertical-align: middle;
			}

			.swiper-pagination-current {
				font-weight: 700;
			}
			.swiper-title {
				display: none;
			}
		}

		.swiper-button-prev {
			top: auto;
			bottom: 43px;
			left: 98px;
		}

		.swiper-button-next {
			top: auto;
			bottom: 43px;
			left: 158px;
			right: auto;
		}

		.swiper-btn {
			@include position(auto, 20px, auto, 40px);

			z-index: 11;

			.swiper-btn-play {
				position: relative;
				display: block;
				padding: 0;
				width: 50px;
				height: 50px;
				text-align: left;
				text-indent: -9999em;

				@include after-btn(-60px, -60px);

				.btn-circle {
					@include position(0, 0);

					width: 100%;
					height: 100%;
					background: rgba(255, 255, 255, .2);
					border-radius: 50%;
					-webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 2px), #fff calc(100% - 1px));
					mask: radial-gradient(farthest-side, transparent calc(100% - 2px), #fff calc(100% - 1px));

					.mask,
					.fill {
						@include position(0, 0);

						width: 50px;
						height: 50px;
						border-radius: 50%;
					}
	
					.mask {
						clip: rect(0px, 50px, 50px, 25px);
				
						.fill {
							clip: rect(0px, 25px, 50px, 0px);
							background-color: rgba(255, 255, 255, 1);
						}
					}
				}

				&.active {
					.btn-circle {
						.mask.full,
						.fill {
							animation: home-visual-btn linear 7s;
							transform: rotate(180deg);
						}
					}
				}

				&.play {
					&::after {
						background-position: -90px -60px;
					}
				}
			}
		}

		.scrolling {
			@include position(auto, 50%, auto, -20px);

			margin-left: -3px;
			width: 7px;
			height: 234px;
			opacity: 1;
			transition: opacity .4s;
			z-index: 1;

			@include dot(7px, 7px, $color1);

			&::before {
				@include position(0, 0);
			}

			.bar {
				position: relative;
				display: block;
				margin: 11px auto 0;
				width: 1px;
				height: 223px;
				overflow: hidden;
				background-color: rgba(0, 0, 0, .2);

				span {
					@include position(0, 0);

					width: 100%;
					height: 100%;
					background-color: $color1;
					animation: scroll-effect1 2s infinite;
				}
			}
			
			&.scroll {
				opacity: 0;
			}
		}
	}

	.ai-area {
		width: 100%;
		height: 2171px;

		.info {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			padding-top: 75px;
			padding-bottom: 75px;
			font-weight: 300;
			text-align: center;
			background-color: $color7;
			overflow: hidden;
	
			span {
				display: block;
				line-height: 37px;
				font-size: 30px;
			}

			strong {
				font-weight: 700;

				&.txt-md {
					font-weight: 500;
				}
			}
		}

		.ai-inner {
			position: -webkit-sticky;
			position: sticky;
			top: 52px;
			margin: 0 auto;
			width: 100%;
			height: 600px;
			overflow: hidden;
		}

		ul {

			@include position-center;

			top: 0;
			transform: translate(-50%, 0);
			width: 100vw;
			height: 1800px;
		}

		li {
			.inner {
				@include background(50%, 100%, $image-url-home+'ai_m_bg1.jpg', $background-size: cover);

				margin: 0;
				padding-top: 222px;
				padding-left: 55px;
				width: 100vw;
				height: 600px;
				color: $color7;
			}

			&:nth-of-type(2) {
				.inner {
					background-image: url($image-url-home+'ai_m_bg2.jpg');
					background-position: 100% 100%;
				}
			}

			&:nth-of-type(3) {
				.inner {
					background-image: url($image-url-home+'ai_m_bg3.jpg');
				}
			}

			span {
				display: block;
			}
	
			br {
				display: none;
			}
	
			strong {
				line-height: 32px;
				font-size: 24px;
			}
	
			p {
				margin-top: 25px;
				line-height: 20px;
				font-size: 14px;
			}
		}

		.dot {
			@include position(0, 20px);

			top: calc(50% - 31px);

			span {
				display: block;
				margin: 12px 0;
				width: 5px;
				height: 5px;
				background-color: $color7;
				border-radius: 50%;
				opacity: .5;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.title-area {
		margin: 0 0 0 20px;
		overflow: hidden;

		h2 {
			line-height: 43px;
			color: $color4;
			font-size: 36px;
			font-family: $font-family2;
			font-weight: 700;

			.txt-amp {
				color: $color1;
				font-size: 40px;
				font-family: $font-family2;
				font-weight: 400;
			}
		}

		p {
			margin-top: 20px;
			line-height: 26px;
			color: $color4;
			font-size: 20px;
			font-weight: 300;

			span {
				display: block;
			}

			br {
				display: none;
			}
		}

		.link-plus {
			display: inline-block;
			margin-top: 20px;
			color: $color7;
			font-size: 13px;
			font-weight: 500;
		}
	}

	.consulting-area {
		@include background(50%, 33%,$image-url-home+'consulting_bg.png', no-repeat, $color4, cover);

		margin-top: 80px;
		padding-top: 75px;
		padding-bottom: 75px;
		overflow: hidden;

		.inner {
			margin: 0;
		}

		.title-area {
			position: relative;
			display: inline-block;
			width: 100%;
			z-index: 5;

			h2 {
				color: $color7;
				font-size: 40px;
				font-weight: 300;
			}

			p {
				color: $color7;
			}
		}

		li {
			position: relative;
			margin-top: 60px;
			padding-left: 80px;

			&:nth-child(1) {
				z-index: 4;
			}

			&:nth-child(2) {
				z-index: 3;
			}

			&:nth-child(3) {
				z-index: 2;
			}

			&:nth-child(4) {
				z-index: 1;
			}

			img {
				width: 100%;
			}

			strong {
				display: inline-block;
				margin-left: 10px;
				line-height: 55px;
				color: $color7;
				font-size: 24px;
				font-weight: 500;
			}

			span {
				display: block;
				margin-left: 10px;
				line-height: 20px;
				color: $color7;
				font-size: 14px;
				opacity: .7;
			}

			.link-plus {
				display: inline-block;
				margin-top: 20px;
				margin-left: 10px;
				color: $color7;
				font-size: 13px;
				line-height: 18px;
				font-weight: 500;

				.icon-plus {
					margin-left: 4px
				}
			}

			&:nth-of-type(2),
			&:nth-of-type(4) {
				padding-left: 0;
				padding-right: 80px;

				strong {
					margin-left: 20px;
				}

				span {
					margin-left: 20px;
				}

				.link-plus {
					margin-left: 20px;
				}
			}
		}
	}

	.learning-area {
		position: relative;
		height: calc(270px * 6 + 263px);

		.inner {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
		}

		.title-area {
			margin-left: 0;
			padding-top: 70px;
			overflow: hidden;

			h2 {
				color: $color4;
			}

			p {
				color: $color4;
			}
		}

		.learning-inner {
			position: -webkit-sticky;
			position: sticky;
			top: 286px;
			margin-top: 40px;
			background-color: $color7;
			overflow: hidden;

			> div {
				position: relative;
				width: calc(270px * 5 + 30px);
			}

			ul {
				padding: 0 10px 0 20px;
				display: flex;
				flex-wrap: nowrap;
				overflow: hidden;
			}

			li {
				flex-basis: 270px;
				flex-shrink: 0;
				width: 270px;
				height: 331px;

				a {
					position: relative;
					display: inline-block;
					margin-right: 10px;
					overflow: hidden;

					figure {
						position: relative;

						&::after {
							@include position(100%, 0);

							content: '';
							width: 100%;
							height: 100%;
							background: linear-gradient(transparent 40%, $color1);
							opacity: .7;
							transition: top .2s;
						}
					}

					&:hover {
						figure {
							position: relative;

							&::after {
								@include position(0, 0);
							}
						}
					}
				}

				.caption {
					@include position(auto, 0, auto, 0);

					display: block;
					padding: 24px 28px;
					width: 100%;
				}

				strong {
					display: block;
					margin: 5px 0 5px;
					line-height: 26px;
					color: $color7;
					font-size: 20px;
					font-weight: 400;
				}

				span {
					color: $color7;
					font-size: 14px;
					opacity: .7;
				}

				.icon {
					margin-left: -3px;
				}

				.icon-plus {
					@include position(56px, auto, 16px);

					margin-left: 0;
				}
			}
		}
	}

	.platform-area {
		padding-bottom: 80px;
		overflow: hidden;

		.title-area {
			padding-top: 76px;
		}

		.platform-inner {
			overflow: hidden;

			h3 {
				line-height: 36px;
				color: $color4;
				font-size: 24px;

				.txt-k {
					@include background(0, 0, $image-url-home+'platform_txt_k.png', $background-size: 100%);

					display: inline-block;
					width: 117px;
					height: 26px;
					text-align: left;
					text-indent: -9999em;
				}
			}

			strong {
				display: block;
				margin-top: 10px;
				margin-bottom: 25px;
				line-height: 21px;
				color: $color4;
				font-size: 15px;
				font-weight: 400;
			}

			p {
				margin-top: 25px;
				line-height: 20px;
				color: $color6;
				font-size: 14px;
			}

			.platform-swiper {
				margin-left: -20px;
				margin-right: -20px;
			}

			.swiper-slide {
				position: relative;
				margin-top: 30px;
				padding: 40px 0 20px 20px;
				min-width: 340px;
				width: 50%;
				min-height: 334px;
				background-color: $color-bg1;

				&.society {
					@include background(100%, 100%, $image-url-home+'platform_bg.jpg', $color: $color1, $background-size: 100%);

					padding-left: 0;
					padding-right: 20px;
					text-align: right;

					h3,
					strong {
						color: $color7;
					}
				}
			}
		}
	}

	.banner-area {
		@include background(0, 0, $image-url-home+'banner_bg.jpg', $background-size: cover);

		display: block;
		height: 339px;
		background-position: 50% 100%;

		.txt-area {
			padding-top: 36px;
			line-height: 28px;
			color: $color7;
			font-size: 22px;
			font-weight: 500;

			span {
				display: block;
			}

			br {
				display: none;
			}

			strong {
				color: #18c6a3;
			}

			em {
				position: relative;
				display: inline-block;
				color: $color2;

				@include dot(5px, 5px, $color2);

				&::before {
					top: auto;
					left: auto;
					bottom: 4px;
					right: -12px;
				}
			}
		}

		.pic-area {
			margin-top: 28px;
			text-align: right;

			img {
				width: 284px;
			}
		}
	}

	.kma60th-area {
		@include background(0, 0, $image-url-home+'kma60th_bg.jpg', $background-size: cover);

		background-position: 50% 0;
		overflow: hidden;

		.title-area {
			position: relative;
			margin: 0;
			text-align: center;
			z-index: 1;

			p {
				padding-top: 60px;
				letter-spacing: .3em;

				img {
					margin-bottom: 20px;
					width: 170px;

					&.img-m {
						display: inline;
					}
					
					&.img-pc {
						display: none;
					}
				}
			}

			h2 {
				margin-top: 38px;
			}
		}

		.culture-list {
			margin-top: -17px;

			ul {
				li {
					figure {
						position: relative;

						img {
							width: 100%;
						}

						figcaption {
							opacity: 0;
							position: absolute;
							padding: 30px;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: linear-gradient(45deg, rgba($color: $color3, $alpha: 0.8) 8%, rgba($color: $color1, $alpha: 0.8)100%);

							strong {
								position: absolute;
								bottom: 30px;
								left: 30px;
								font-family: $font-family2;
								color: $color7;
								font-size: 20px;
								line-height: 26px;
								font-weight: 400;

								p {
									margin-top: 8px;
									color: $color7;
									font-size: 14px;
									line-height: 18px;
									font-weight: 400;
								}
							}

						}
					}

					&+li {
						margin-top: 30px;
					}

					&:hover {
						figcaption {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.news-area {
		padding-top: 90px;
		padding-bottom: 80px;

		.title-area {
			margin: 0;
			overflow: hidden;

			h2 {
				a {
					margin: 0;
					line-height: 43px;
					color: $color4;
					font-size: 40px;
					font-weight: 500;
					font-family: $font-family2;

					.icon-plus {
						margin-top: 14px;	
						margin-left: 14px;
						width: 24px;
						height: 24px;
						background-color: $color-bg3;

						&::before {
							width: 2px;
							height: 10px;
							background-color: $color1;
						}
						&::after {
							width: 10px;
							height: 2px;
							background-color: $color1;
						}
					}
				}
			}
		}

		.news-inner {
			display: flex;
			flex-direction: column;
			margin-top: 5px;

			a {
				position: relative;
				margin-top: 20px;
				padding: 25px 25px 0;
				display: block;
				background-color: $color-bg1;
			}

			.txt-category {
				display: block;
				color: $color1;
				font-size: 13px;
				font-weight: 700;
			}

			strong {
				@include ellipsis(3);

				margin-top: 20px;
				padding-right: 20px;
				height: 63px;
				line-height: 21px;
				color: $color4;
				font-size: 15px;
			}

			.txt-date {
				margin-top: 20px;
				display: block;
				color: $color6;
				font-size: 11px;
			}

			figure {
				margin-top: 10px;
				margin-left: -25px;

				img {
					width: 100%;
				}
			}

			.icon-plus {
				@include position(25px, auto, 20px);
			}
		}
	}

	.qna-area {
		@include background(0, 0, $image-url-home+'qna_bg.jpg', $background-size: cover);

		margin: 0 0 80px;
		padding: 35px 20px 0;
		display: block;
		height: 198px;
		background-position: 60% 0;

		&::before {
			@include position(0, 0);

			content: '';
			width: 100%;
			height: 100%;
			background: linear-gradient(to left, transparent 40%, $color4);
			z-index: 0;
		}

		.title-area {
			position: relative;
			margin: 0;
			z-index: 1;

			h2 {
				line-height: 26px;
				color: $color7;
				font-size: 20px;
				font-weight: 500;
				font-family: $font-family1;
			}
		}

		.btns {
			position: relative;
			margin-top: 25px;
			width: 142px;
			z-index: 1;
		}
	}

	.loadcont {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color4;
		z-index: 100;

		.inner {
			position: relative;
			margin: 0 auto;
			width: 100%;
			height: 100%;
		}

		.circle {
			@include position(50%, 50%);

			margin-top: -2px;
			width: 306px;
			height: 306px;
			transform: translate(-50%, -50%);
			border: 1px solid #ffffff;
			border-radius: 50%;
			
			&.blue {
				transform: translate(-100%, -50%) scale(1);
				border-color: rgba(88, 118, 244, .5);
				animation: loadcont-effect1 1.8s ease-in both;
			}
			
			&.red {
				transform: translate(0, -50%) scale(1);
				border-color: rgba(255, 33, 33, .5);
				animation: loadcont-effect2 1.8s ease-in both;
			}
		}

		.logo {
			@include position-center;
			@include background(0, 0, $image-url-home + 'loadcont_logo.png', $background-size: 100%);

			width: 89px;
			height: 24px;
			text-align: left;
			text-indent: -9999em;
			transform: translate(-50%, -50%) scale(0);
			opacity: 0;
			animation: zoom-center-effect1 .8s ease-in both 1s;
		}
	}
}

@include tablet {
	.home {
		.visual-area {
			.swiper-slide {
				.txt {
					line-height: rem(44px);
					font-size: rem(32px);

					strong {
						margin-bottom: rem(30px);
						line-height: rem(90px);
						font-size: rem(80px);

						&.kma60th {
							margin-bottom: rem(30px);
							width: rem(380px);
							height: rem(270px);
						}
					}

					p {
						.bar {
							margin: rem(14px) rem(20px) 0;
							height: rem(2px);
							width: rem(53px);
						}
					}
				}

				.cover {
					.circle {
						width: rem(3840px);
						height: rem(3840px);
					}

					.line {
						&::before {
							width: rem(1770px);
							height: rem(1770px);
						}

						&::after {
							width: rem(1115px);
							height: rem(1115px);
						}
					}

					&.gray {
						.circle {
							background-image: url($image-url-home+'visual_cover_gray.png');
						}
					}
				}
			}

			.swiper-pagination {
				bottom: 38px;
				padding-left: 168px;
				line-height: 64px;

				&::before {
					top: 33px;
					left: 96px;
					width: 46px;
					height: 1px;
				}

				.swiper-pagination-current,
				.swiper-pagination-total {
					width: rem(26px);
					font-size: rem(14px);
				}

				.swiper-title {
					@include ellipsis;

					display: inline-block;
					margin-left: rem(5px);
					width: rem(200px);
					font-size: rem(14px);
					text-align: center;
					vertical-align: middle;
				}
			}

			.swiper-button-next {
				left: 420px;
				bottom: 49px;
			}

			.swiper-button-prev {
				left: 143px;
				bottom: 49px;
			}

			.swiper-btn {
				.swiper-btn-play {
					width: 64px;
					height: 64px;

					.btn-circle {
						.mask,
						.fill {
							width: 64px;
							height: 64px;
						}
		
						.mask {
							clip: rect(0px, 64px, 64px, 32px);
					
							.fill {
								clip: rect(0px, 32px, 64px, 0px);
								background-color: rgba(255, 255, 255, 1);
							}
						}
					}
				}
			}

			.scrolling {
				bottom: -55px;
				margin-left: -5px;
				width: 11px;
				height: 158px;
				z-index: 11;

				&::before {
					width: 11px;
					height: 11px;
				}

				.bar {
					margin-top: 18px;
					height: 140px;
				}
			}
		}

		.ai-area {
			height: rem(2294px);

			.info {
				top: rem(-100px);
				padding-top: rem(164px);
				padding-bottom: rem(150px);

				span {
					display: inline;
					line-height: rem(60px);
					font-size: rem(40px);

				}
			}

			.ai-inner {
				top: rem(394px);
				height: rem(600px);
				max-width: rem(1800px);
			}

			ul {
				height: rem(1800px);
			}

			li {
				.inner {
					padding: rem(208px) rem(120px) 0;
					height: rem(600px);
					background-size: cover;
					background-position: 80% 100%;
				}

				&:nth-of-type(2) {
					.inner {
						padding-left: 45%;
						padding-right: 0;
						background-position: 50% 100%;
					}
				}

				&:nth-of-type(3) {
					.inner {
						background-position: 50% 60%;
					}
				}

				span {
					display: inline;
				}
		
				br {
					display: block;
				}
		
				strong {
					line-height: rem(56px);
					font-size: rem(36px);
				}
		
				p {
					margin-top: rem(30px);
					line-height: rem(30px);
					font-size: rem(18px);
				}
			}

			.dot {
				top: 50%;
				left: 42px;
				transform: translateY(-50%);

				span {
					margin-top: 20px;
					margin-bottom: 20px;
					width: 8px;
					height: 8px;
				}
			}
		}

		.title-area {
			margin: 0;

			h2 {
				line-height: rem(70px);
				font-size: rem(56px);

				.txt-amp {
					font-size: rem(60px);
				}
			}

			p {
				margin-top: rem(19px);
				line-height: rem(36px);
				font-size: rem(24px);

				span {
					display: inline;
				}

				br {
					display: block;
				}
			}

			.link-plus {
				margin-left: rem(5px);
				font-size: rem(14px);
			}
		}

		.consulting-area {
			margin-top: rem(120px);
			padding-top: 0;
			padding-bottom: rem(150px);
			background-position-y: 50%;
			background-size: 100%;

			.title-area {
				margin: 0;
	
				h2 {
					line-height: rem(70px);
					font-size: rem(60px);
					font-weight: 400;
				}

			}

			.inner {
				margin: 0 20px;
			}

			.title-area {
				padding-top: rem(187px);
				width: 50%;
			}

			li {
				margin-top: rem(-380px);
				padding: 0;
				text-align: right;


				>div {
					display: inline-block;
					text-align: left;
					width: 43.5%;
				}

				.link-plus {
					margin-top: rem(30px);
					margin-left: rem(20px);
					font-size: rem(14px);
					line-height: rem(36px);

					.icon-plus {
						margin-left: rem(10px);
						vertical-align: middle;
						width: 24px;
						height: 24px;
					}
				}

				strong {
					margin-top: rem(15px);
					margin-left: rem(20px);
					line-height: rem(82px);
					font-size: rem(36px);
				}

				span {
					margin-left: rem(20px);
					line-height: rem(30px);
					font-size: rem(18px);
				}

				&:nth-of-type(1) {
					margin-top: rem(-260px);
				}

				&:nth-of-type(2) {
					padding-right: 0;
					text-align: left;
				}
				
				&:nth-child(3) {
					margin-top: rem(-280px);
				}

				&:nth-of-type(4) {
					margin-top: rem(-280px);
					padding-right: 0;
					text-align: left;
				}
			}
		}

		.learning-area {
			height: calc(436px * 6 + 267px - 1250px);

			.title-area {
				margin: 0 auto;
				padding: rem(126px) 0 0;
				max-width: 1280px;
				font-size: 0;

				h2 {
					display: inline-block;
					width: 50%;
				}
	
				p {
					margin-top: rem(10px);
					display: inline-block;
					width: 50%;
				}
			}

			.learning-inner {
				top: rem(310px);
				margin-top: rem(43px);

				> div {
					width: calc(436px * 5 + 30px);
				}

				li {
					flex-basis: rem(436px);
					width: rem(436px);
					height: rem(520px);

					a {
						margin-right: rem(28px);
					}
	
					.caption {
						padding: rem(40px);
					}
	
					strong {
						margin: rem(10px) 0 rem(10px);
						line-height: rem(36px);
						font-size: rem(28px);
					}
	
					span {
						font-size: rem(16px);
					}
	
					.icon {
						margin-left: -3px;
					}
	
					.icon-plus {
						top: rem(82px);	
						margin-left: 0;
					}
				}
			}
		}

		.platform-area {
			margin: 0 auto;
			padding-bottom: rem(127px);
			width: calc(100% - 40px);
			max-width: 1280px;

			.title-area {
				padding-top: rem(131px);
				font-size: 0;

				h2 {
					display: inline-block;
					line-height: rem(60px);
					width: 50%;
				}
	
				p {
					display: inline-block;
					margin-top: 0;
					width: 50%;
					vertical-align: bottom;
				}
			}

			.platform-inner {
				h3 {
					line-height: rem(48px);
					font-size: rem(36px);

					.txt-k {
						margin-top: rem(7px);
						margin-left: rem(26px);
						transform: scale(1.4);
						vertical-align: top;
					}
				}

				strong {
					margin-top: rem(19px);
					margin-bottom: rem(39px);
					line-height: rem(30px);
					font-size: rem(20px);
				}

				p {
					margin-top: rem(33px);
					line-height: rem(26px);
					font-size: rem(16px);
				}

				.swiper-slide {
					margin-top: rem(41px);
					padding-top: rem(64px);
					padding-left: rem(76px);
					padding-bottom: rem(57px);
					width: 50%;
					height: calc(50% * 0.8125);

					&.society {
						padding-right: rem(71px);

						strong {
							margin-top: rem(26px);
							margin-bottom: rem(32px);
						}	

						p {
							color: $color7;
							margin-top: rem(23px);
						}
					}
				}
			}
		}

		.banner-area {
			height: rem(260px);
			background-size: 120%;
			background-position: 10% 75%;

			.txt-area {
				padding-top: rem(82px);
				padding-left: 0;
				height: rem(260px);
				line-height: rem(48px);
				font-size: rem(32px);

				span {
					display: inline;
				}

				br {
					display: block;
				}

				em {
					&::before {
						bottom: rem(10px);
						right: rem(-20px);
						width: rem(10px);
						height: rem(10px);
					}
				}
			}

			.pic-area {
				position: absolute;
				top: rem(32px);
				right: 0;
				margin-top: 0;
				height: 100%;
				text-align: right;

				img {
					width: auto;
					height: 80%;
				}
			}
		}

		.kma60th-area {
			.title-area {
				p {
					img {
						margin: 0 auto rem(40px);
						width: 240px;

						&.img-m {
							display: none;
						}

						&.img-pc {
							display: block;
						}
					}
				}

				h2 {
					margin-top: rem(94px);
					font-size: rem(80px);

					.txt-amp {
						font-size: rem(80px);
					}
				}
			}

			.culture-list {
				margin-top: rem(-8px);

				ul {
					display: flex;

					li {
						display: inline-block;
						width: calc(100% / 3 - 29px);

						figure {
							figcaption {
								strong {
									bottom: rem(40px);
									left: rem(40px);
									font-size: rem(28px);
									line-height: rem(36px);

									p {
										margin-top: 8px;
										font-size: rem(16px);
										line-height: rem(36px);
									}
								}

							}
						}

						&+li {
							margin-top: 0;
							margin-left: rem(58px);
						}
					}
				}
			}

		}


		.news-area {
			padding-top: rem(105px);
			padding-bottom: rem(120px);

			.title-area {
				h2 {
					a {
						position: relative;
						line-height: rem(60px);
						font-size: rem(60px); 

						br {
							display: none;
						}

						.icon-plus {
							position: absolute;
							top: 0;
							right: rem(-60px);
							margin-top: rem(14px);
							margin-left: rem(10px);
							width: rem(40px);
							height: rem(40px);

							&::before {
								width: rem(3px);
								height: rem(15px);
							}
							&::after {
								width: rem(15px);
								height: rem(3px);
							}
						}
					}
				}
			}

			.news-inner {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-top: rem(5px);

				a {
					flex: 1 1 0;
					margin-top: rem(20px);
					margin-left: rem(28px);
					padding: rem(40px) rem(30px) 0 rem(33px);

					&:first-of-type {
						margin-left: 0;
					}
				}

				.txt-category {
					font-size: rem(14px);
				}

				strong {
					margin-top: rem(27px);
					padding-right: rem(30px);
					height: rem(90px);
					line-height: rem(30px);
					font-size: rem(20px);
				}

				.txt-date {
					margin-top: rem(44px);
					font-size: rem(12px);
				}

				figure {
					margin-top: rem(33px);
					margin-left: rem(-33px);
				}

				.icon-plus {
					top: rem(34px);
					right: rem(28px);
				}
			}
		}

		.qna-area {
			margin: 0 auto rem(80px);
			padding: rem(62px) 20px 0;
			height: rem(200px);
			background-position: 50% 0;

			&::before {
				display: none;
			}

			.title-area {
				margin: 0;

				h2 {
					line-height: rem(40px);
					font-size: rem(28px);
				}
			}

			.btns {
				@include position(rem(71px), auto, 20px);

				margin-top: 0;
				width: rem(275px);

				.btn {
					height: rem(64px);
					line-height: rem(62px);
					font-size: rem(18px);
				}
			}
		}

		.loadcont {
			.circle {
				width: 524px;
				height: 524px;
			}
		}
	}
}

@include desktop-min {
	.home {
		.visual-area {
			.swiper-pagination {
				bottom: 59px;
				padding-left: 188px;

				&::before {
					left: 116px;
				}
			}

			.swiper-button-next {
				left: 440px;
				bottom: 69px;
			}

			.swiper-button-prev {
				left: 163px;
				bottom: 69px;
			}

			.swiper-btn {
				left: 40px;
				bottom: 60px;
			}
		}

		.consulting-area {
			.inner {
				margin: 0 40px;
			}
		}

		.learning-area {
			.learning-inner {
				ul {
					padding: 0 30px 0 40px;
				}
			}
		}

		.platform-area {
			.title-area {
				margin: 0 20px;
			}
		}

		.qna-area {
			padding-left: 40px;
			padding-right: 40px;

			.btns {
				right: 40px;
			}
		}

		.loadcont {
			.circle {
				width: 620px;
				height: 620px;
			}
		}
	}
}

@include desktop {
	.home {
		.visual-area {
			.swiper-slide {
				&.slide1 {
					.pic {
						object-position: 50% 50%;
					}
				}
			}
		}

		.ai-area { 
			li {
				text-align: center;

				.inner {
					margin: 0;
					max-width: 1800px;
					text-align: left;
				}
			}
		}

		.consulting-area {
			.inner {
				margin: 0 auto;
			}
		}

		.learning-area {
			.learning-inner {
				ul {
					padding: 0;
				}
			}
		}

		.platform-area {
			.title-area {
				margin: 0;
			}
		}

		.banner-area {
			.txt-area {
				padding-left: rem(94px);
			}

			.pic-area {
				right: rem(120px);
			}
		}

		.qna-area {
			padding-left: rem(95px);
			padding-right: rem(95px);

			.btns {
				right: rem(78px);
			}
		}

		.loadcont {
			.circle {
				margin-top: -4px;
				width: 716px;
				height: 716px;
			}
		}
	}
}
